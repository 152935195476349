<template>
    <div class="case-container">
        <div class="add-case-title">{{caseTitle}}</div>
        <el-form :model="addCaseForm" ref="walletForm" label-position="left" :rules="addCaseFormRules" class="case-box">
            <el-scrollbar class="main-content">
                <el-form-item label="案例名称" :label-width="formLabelWidth" prop="name">
                    <el-input v-model="addCaseForm.name" placeholder="请输入案例名称1-100字" autocomplete="off"
                              style="width: 60%;"></el-input>
                </el-form-item>
                <el-form-item label="案例分类" :label-width="formLabelWidth" prop="classification_id">
                    <el-select v-model="addCaseForm.classification_id" placeholder="请选择案例分类"
                               @change="classClassify($event)" class="select-green" style="width:60%;">
                        <el-option v-for="item in selectClassify" :key="item.id" :label="item.name" :value="item.id">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="学校类型" :label-width="formLabelWidth" prop="school_type_id" v-if="addCaseForm.classification_id!==4">
                    <el-select v-model="addCaseForm.school_type_id" placeholder="请选择学校类型" @change="classSchool($event)"
                               class="select-green" style="width: 60%;">
                        <el-option v-for="item in selectSchool" :key="item.id" :label="item.name" :value="item.id">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="封面上传" :label-width="formLabelWidth" prop="cover_path">
                    <el-upload class="imgBox" action accept="image/jpeg,image/png" list-type="picture-card"
                               :show-file-list="false" :http-request="uploadCover"
                               :on-preview="handlePictureCardPreview">
                        <div :class="this.addCaseForm.cover_url?'imgUpload':'bgColor'">
                            <i class="el-icon-circle-plus"></i>
                            <el-button style="position: relative;font-size: 14px;" size="small" type="text">
                                {{addCaseForm.cover_url?'重新上传':'点击上传'}}
                            </el-button>
                        </div>
                        <img :src="addCaseForm.cover_url" v-if="addCaseForm.cover_url">
                    </el-upload>
                    <span class="cover-prompt">建议图片尺寸：<em style="color: #FF0101;font-style:normal">280*200</em></span>
                </el-form-item>
                <el-form-item label="logo上传" :label-width="formLabelWidth" prop="logo_path">
                    <el-upload class="logoBox" action accept="image/jpeg,image/png" list-type="picture-card"
                               :show-file-list="false" :http-request="changeFile"
                               :on-preview="handlePictureCardPreview">
                        <div :class="this.addCaseForm.logo_url?'imgUpload':'bgColor'">
                            <i class="el-icon-circle-plus"></i>
                            <el-button style="position: relative;font-size: 14px;" size="small" type="text">
                                {{addCaseForm.logo_url?'重新上传':'点击上传'}}
                            </el-button>
                        </div>
                        <img :src="addCaseForm.logo_url" v-if="addCaseForm.logo_url">
                    </el-upload>
                    <span class="cover-prompt">建议比例：<em style="color: #ff0101;font-style:normal">1:1</em></span>
                </el-form-item>
                <el-form-item :label-width="formLabelWidth" class="content-item" prop="content"
                              v-for="(item,index) in addCaseForm.content" :key="index">
                    <div class="case-content-header">
                        <div class="attr-item-title">
                            <div class="title" v-show="!isEdit[index]">
                                <em>*</em>
                                <span>{{item.name}}</span>
                                <i class="el-icon-edit-outline" @click="editTitle(index)"></i>
                            </div>
                            <el-input v-model="item.name" v-show="isEdit[index]" @keyup.enter.native="saveTitle(index)">
                                <el-button slot="append" @click="saveTitle(index)">确定</el-button>
                            </el-input>
                        </div>
                        <div class="del-content" v-if="index!=0">
                            <el-button size="medium" type="danger" @click="delContent(index)">删除</el-button>
                        </div>
                    </div>
                    <tiny-editor :id="'tiny-item'+index" :init="tiny_init" v-model="item.content"></tiny-editor>
                </el-form-item>
                <div class="add-content">
                    <el-button size="medium" class="btn-green" @click="addContent()">添加内容</el-button>
                </div>
            </el-scrollbar>
        </el-form>
        <div class="footer-btn" slot="footer">
            <el-button size="medium" @click="backCase">返回</el-button>
            <el-button size="medium" @click="resetCase">重置</el-button>
            <el-button size="medium" class="btn-green" @click="commitCase">提交</el-button>
        </div>
    </div>
</template>

<script>
    import {addCase, getClassification, getSchooltype, cover, logo, oneCase, editCase} from '@/utils/apis'
    import Editor from "@tinymce/tinymce-vue"
    import image from "tinymce/plugins/image/plugin.min"
    export default {
        name: "Detail",
        components: {
            'tiny-editor': Editor
        },
        data() {
            return {
                caseTitle:'',
                addCaseForm: {
                    name: '',
                    classification_id: '',
                    school_type_id: '',
                    cover_url: null,
                    cover_path: null,
                    logo_url: '',
                    logo_path: '',
                    content: [
                        {
                            name: '方案内容',
                            content: ''
                        }
                    ],

                },
                isEdit: [false],
                selectClassify: [],//案例分类
                selectSchool: [],//学校分类
                formLabelWidth: '80px',
                addCaseFormRules: {
                    name: [
                        {required: true, message: '请输入案例名称', trigger: 'change'},
                        {min: 1, max: 100, message: '长度在 1 到 100 个字', trigger: 'blur'}
                    ],
                    classification_id: [
                        {required: true, message: '请选择案例分类', trigger: 'blur'},
                    ],
                    school_type_id: [
                        {required: true, message: '请选择学校类型', trigger: 'blur'},
                    ],
                    cover_path: [
                        {required: true, message: '请选择上传封面', trigger: 'blur'},
                    ],
                    content: [
                        {required: true, message: '请填写方案内容', trigger: 'blur'},
                    ],
                },
                //富文本框设置
                tiny_init: {
                    base_url: '/tinymce/',
                    menubar: false,
                    language: 'zh_CN',
                    plugins: "lists, advlist, emoticons, fullscreen,table, insertdatetime, wordcount, image",
                    toolbar: [
                        'undo redo | styleselect | bold italic underline strikethrough|fontselect fontsizeselect formatselect|alignleft aligncenter image emoticons forecolor| backcolor insertdatetime wordcount|table tabledelete|fullscreen|',
                    ],
                    toolbar_drawer: "sliding",
                    // quickbars_selection_toolbar: "removeformat | bold italic underline strikethrough | fontsizeselect forecolor backcolor",
                    height: 300,
                    width: 1068,
                    //上传图片
                    images_upload_handler: function (blobInfo, success, failure, progress) {
                        var xhr, formData;
                        xhr = new XMLHttpRequest();
                        xhr.withCredentials = false;
                        xhr.open('POST', 'case/content/image');//后端给的上传图片接口
                        xhr.setRequestHeader("Authorization", localStorage.getItem('adminToken'));
                        xhr.upload.onprogress = function (e) {
                            progress(e.loaded / e.total * 100);
                        }
                        xhr.onload = function () {
                            var json;
                            if (xhr.status == 403) {
                                failure('HTTP Error: ' + xhr.status, {remove: true});
                                return;
                            }
                            if (xhr.status < 200 || xhr.status >= 300) {
                                failure('HTTP Error: ' + xhr.status);
                                return;
                            }
                            json = JSON.parse(xhr.responseText);
                            if (!json || typeof json.location != 'string') {
                                failure('Invalid JSON: ' + xhr.responseText);
                                return;
                            }
                            success(json.location);
                        };
                        xhr.onerror = function () {
                            failure('Image upload failed due to a XHR Transport error. Code: ' + xhr.status);
                        }
                        formData = new FormData();
                        formData.append('image', blobInfo.blob(), blobInfo.filename());
                        xhr.send(formData);
                    }
                },
            }
        },
        mounted() {
            if (this.$route.query.id) {
                this.getCaseById(this.$route.query.id)
                this.caseTitle = '编辑案例'
            }else {
                this.caseTitle = '新增案例'
            }
            this.getClassificationList()
            this.getSchoolTypeList()
        },
        methods: {
            getCaseById(id) {
                // let formData = new FormData();
                // formData.append("id", id);
                let formData = {
                    id: id
                }
                oneCase(formData).then(res => {
                    this.addCaseForm = {
                        name: res.data.name,
                        classification_id: res.data.classification_id,
                        classification_name: res.data.classification_name,
                        school_type_id: res.data.school_type_id,
                        school_type_name: res.data.school_type_name,
                        cover_url: res.data.cover_path,
                        logo_url: res.data.logo_path,
                        cover_path: res.data.cover_path_url,
                        logo_path: res.data.logo_path_url,
                        content: res.data.content
                    }
                    let arr = [];
                    for (let i in res.data.content) {
                        arr.push(false)
                    }
                    this.isEdit = arr;

                }).catch(err => {
                    console.error(err)
                })
            },
            // 获取案例分类
            getClassificationList() {
                getClassification().then((res) => {
                    this.selectClassify = res.data;
                }).catch((err) => {
                    console.log('err', err)
                })
            },
            // 获取学校分类
            getSchoolTypeList() {
                getSchooltype().then((res) => {
                    this.selectSchool = res.data;
                }).catch((err) => {
                    console.log('err', err)
                })
            },
            // 案例分类选择
            classClassify(e) {
                this.addCaseForm.classification_id = e;
            },
            // 学校
            classSchool(e) {
                this.addCaseForm.school = e;
            },
            // 上传封面
            uploadCover(param) {
                let file = param.file
                let maxSize = 1024 * 1024 * 2;
                let reader = new FileReader();
                reader.readAsDataURL(file);//读取图像文件 result 为 DataURL, DataURL 可直接 赋值给 img.src
                reader.onload = (event) => {
                    let img = new Image();
                    // console.log(param, "param");
                    img.src = event.target.result;
                    img.onload = () => {
                        if (img.width === 280 && img.height === 200 && file.size <= maxSize) {
                            let formData = new FormData();
                            formData.append("cover", file);
                            console.log(formData, 'formData')
                            cover(formData).then((res) => {
                                this.$message.success(res.msg);
                                this.addCaseForm.cover_url = res.data.url;
                                this.addCaseForm.cover_path = res.data.path;
                            }).catch((err) => {
                                console.log('err', err)
                            })
                        } else {
                            this.$message({
                                type: "error",
                                message: "图片尺寸必须为 280*200",
                            });
                        }
                    };

                }
            },
            //上传logo
            changeFile(param) {
                let file = param.file
                let maxSize = 1024 * 1024 * 2;
                let reader = new FileReader();
                reader.readAsDataURL(file);//读取图像文件 result 为 DataURL, DataURL 可直接 赋值给 img.src
                reader.onload = (event) => {
                    let img = new Image();
                    // console.log(param, "param");
                    img.src = event.target.result;
                    img.onload = () => {
                        if (img.width === img.height && file.size <= maxSize) {
                            let formData = new FormData();
                            formData.append("logo", file);
                            console.log(formData, 'formData')
                            logo(formData).then((res) => {
                                this.$message.success(res.msg);
                                this.addCaseForm.logo_url = res.data.url;
                                this.addCaseForm.logo_path = res.data.path;
                            }).catch((err) => {
                                console.log('err', err)
                            })
                        } else {
                            this.$message({
                                type: "error",
                                message: "图片尺寸必须为 1:1",
                            });
                        }
                    };

                }
            },
            handlePictureCardPreview(file) {
                // this.addScriptForm.cover_path = file.url;
            },
            addContent() {
                // 添加富文本
                let ob = {
                    name: '方案内容',
                    is_edit: false
                }
                this.addCaseForm.content.push(ob);
                this.isEdit.push(false)
            },
            delContent(index) {
                // 删除富文本
                this.addCaseForm.content.splice(index, 1);

            },
            // 编辑
            editTitle(index) {
                // this.isEdit[index] = true;
                this.$set(this.isEdit, index, true)
            },
            //保存
            saveTitle(index) {
                if (this.addCaseForm.content[index].name) {
                    // this.isEdit[index] = false;
                    this.$set(this.isEdit, index, false)
                }

            },
            // 返回
            backCase() {
                this.$router.go(-1)
            },
            // 重置
            resetCase() {
                this.addCaseForm = this.$options.data().addCaseForm;
                this.addCaseForm = {
                    name: '',
                    classification_id: '',
                    school_type_id: '',
                    cover_url: '',
                    cover_path: '',
                    logo_url: '',
                    logo_path: '',
                    content: [
                        {
                            name: '',
                            content: '',
                        }
                    ],
                }
                this.isEdit = [false]
            },
            // 新增案例-提交
            commitCase() {
                if (this.$route.query.id) {
                    //修改案例 editCase
                    let id = this.$route.query.id
                    let params= {
                            name:this.addCaseForm.name,
                            classification_id:this.addCaseForm.classification_id,
                            school_type_id:this.addCaseForm.school_type_id,
                            cover_path:this.addCaseForm.cover_path_url,
                            logo_path:this.addCaseForm.logo_path_url,
                            content:JSON.stringify(this.addCaseForm.content)
                    }
                    editCase(id,params).then((res) => {
                        this.$message.success(res.msg);
                        this.$router.push({
                            path: '/caseManage/index',
                        })
                    }).catch((err) => {
                        console.log('err', err)
                    })
                } else {
                    // 新增案例
                    let formData = new FormData();
                    formData.append("name", this.addCaseForm.name);
                    formData.append("classification_id", this.addCaseForm.classification_id);
                    formData.append("school_type_id", this.addCaseForm.school_type_id);
                    formData.append("cover_path", this.addCaseForm.cover_path);
                    formData.append("logo_path", this.addCaseForm.logo_path);
                    formData.append("content", JSON.stringify(this.addCaseForm.content));
                    addCase(formData).then((res) => {
                        this.$message.success(res.msg);
                        this.$router.push({
                            path: '/caseManage/index',
                        })
                    }).catch((err) => {
                        console.log('err', err)
                    })
                }
            },
        }
    }
</script>

<style scoped lang="scss">
    .case-container {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
        padding: 0 20px;
        .add-case-title {
            padding: 20px 0;
            font-size: 16px;
            color: #222;
            border-bottom: 1px solid #eee;
        }
        .case-box {
            height: 78%;
            margin-top: 60px;
        }
        .main-content {
            height: 100%;
            ::v-deep .el-scrollbar__wrap {
                overflow-x: hidden;
            }
            .imgBox {
                .imgUpload {
                    display: flex;
                    flex-direction: column;
                    position: absolute;
                    background: rgba(0, 0, 0, 0.3);
                    width: 280px;
                    height: 200px;
                    justify-content: center;
                    ::v-deep .el-button--text {
                        color: #fff;
                    }
                    ::v-deep .el-icon-circle-plus {
                        color: #fff;
                    }
                }
                .bgColor {
                    background: #f2f2f2;
                    width: 280px;
                    height: 200px;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                }
                ::v-deep .el-upload--picture-card {
                    width: 280px;
                    height: 200px;
                    line-height: 200px;
                    border: none;
                }
            }
            .logoBox {
                .imgUpload {
                    display: flex;
                    flex-direction: column;
                    position: absolute;
                    background: rgba(0, 0, 0, 0.3);
                    width: 100px;
                    height: 100px;
                    justify-content: center;
                    ::v-deep .el-button--text {
                        color: #fff;
                    }
                    ::v-deep .el-icon-circle-plus {
                        color: #fff;
                    }
                }
                ::v-deep .el-upload--picture-card {
                    width: 100px;
                    height: 100px;
                    line-height: 100px;
                    border: none;
                }
                .bgColor {
                    background: #f2f2f2;
                    width: 100px;
                    height: 100px;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                }
            }
            img {
                max-width: 100%;
                max-height: 100%;
            }
        }
        .footer-btn {
            display: flex;
            justify-content: center;
        }
        .case-content-header {
            display: flex;
            align-items: center;
            margin: 10px 0;
            .attr-item-title {
                width: 84%;
                .title {
                    display: flex;
                    align-items: center;
                    em {
                        color: #F56C6C;
                        margin-top: 10px;
                    }
                    span {
                        font-size: 16px;
                        display: inline-block;
                        margin: 0 10px;
                    }
                    .el-icon-edit-outline {
                        color: #20C2C9;
                        font-size: 20px;
                        cursor: pointer;
                    }
                }
            }
            .del-content {
                margin: 16px;
            }
        }
    }
    ::v-deep .el-button--text {
        color: #666;
    }
    ::v-deep .el-upload--picture-card i {
        color: #ff0013;
    }
    ::v-deep .el-input__inner {
        height: 46px;
        line-height: 46px;
    }
    .content-item{
        ::v-deep .el-form-item__content{
            margin-left: 0!important;
        }
    }
    .add-content {
        padding: 10px 0;
    }
</style>